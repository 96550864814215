import { useAuth, useUser } from '@clerk/clerk-react';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@repo/ui/components/ui/avatar";
import { Badge } from "@repo/ui/components/ui/badge";
import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import { Separator } from "@repo/ui/components/ui/separator";
import { useQuery } from '@tanstack/react-query';
import { ArrowBigUp, ExternalLink, MessageCircle, Scan } from "lucide-react";
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import remarkGfm from 'remark-gfm';
import invariant from 'tiny-invariant';
import { Spinner } from "../organisms/";
import { fetcher, serializeError } from '../services/api';
import { usePrefsStore } from '../store';


const timeAgo = (timestamp: string): string => {
  const now = new Date();
  const pastDate = new Date(timestamp);
  const seconds = Math.floor((now - pastDate) / 1000);

  if (seconds < 60) return `${seconds}s`;
  const minutes = Math.floor(seconds / 60);
  if (minutes < 60) return `${minutes}m`;
  const hours = Math.floor(minutes / 60);
  if (hours < 24) return `${hours}h`;
  const days = Math.floor(hours / 24);
  if (days < 30) return `${days}d`;
  const months = Math.floor(days / 30);
  if (months < 12) return `${months}mo`;
  const years = Math.floor(days / 365);
  return `${years}y`;
}


const RedditInteractions = () => {
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();
  const currentProject = usePrefsStore((state) => state.currentProject)

  const { data, error, status } = useQuery({
    queryKey: ["get", "reddit", "submission-candidates", currentProject.id],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/reddit/submission-candidates", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          query: {
            project_id: currentProject.id,
            expand: ["submission", "submission.discovery", "submission.stats"],
            limit: 50,
            order_by: "relevance_score",
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  if (status === "pending") {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }

  return (
    <div className="flex flex-col gap-8">

      <div className="flex justify-between">
        <h1 className="text-3xl font-semibold">Reddit opportunities</h1>
      </div>

      <div className="flex flex-col gap-4">
        {
          data.data.map((rs, i) => {
            invariant(rs.submission, "Unexpected missing Reddit submission")
            invariant(rs.submission.discovery, "Unexpected missing Reddit submission.discovery")
            invariant(rs.submission.stats, "Unexpected missing Reddit submission.stats")

            const sortedStats = rs.submission.stats
              .sort((a, b) => new Date(b.fetched_at) - new Date(a.fetched_at));
            const stats = sortedStats[0];

            return (
              <Card
                key={`card-${i}-${rs.submission}`}
                className="min-w-80 w-full"
              >
                <CardHeader>
                  <div className="flex justify-between items-center">

                    <div className="flex items-center gap-2">
                      <Avatar>
                        <AvatarImage src={rs.submission.sub_avatar_link} alt={rs.submission.sub} />
                        <AvatarFallback>{rs.submission.sub[0]}</AvatarFallback>
                      </Avatar>
                      <div className="flex flex-col">
                        <span className="text-xs font-semibold">r/{rs.submission.sub}</span>
                        <span className="flex gap-2 text-xs">
                          <span className="text-xs">u/{rs.submission.author}</span>
                          ·
                          <span className="text-xs">{timeAgo(rs.submission.submitted_at)}</span>
                        </span>
                      </div>
                    </div>

                    <div className="flex gap-1">
                      <div
                        className="flex justify-center items-center bg-muted rounded-md pr-2"
                      >
                        <div className="w-10 h-10 flex justify-center items-center">
                          <Scan className="size-5" />
                        </div>
                        <span className="text-xs">Discovered {timeAgo(rs.submission.discovery?.ran_at)} ago</span>
                      </div>
                      <Badge
                        variant={rs.relevance_score < 50 ? "error" :
                          rs.relevance_score < 80 ? "warning" : "success"}
                        className="text-md"
                      >
                        {rs.relevance_score}
                      </Badge>
                      <Button
                        variant="secondary"
                        size="icon"
                        aria-label="Edit project"
                        asChild
                      >
                        <a href={rs.submission.link} target="_blank">
                          <ExternalLink className="size-5" />
                        </a>
                      </Button>
                    </div>

                  </div>
                  <CardTitle className="text-lg">{rs.submission.title}</CardTitle>
                </CardHeader>
                <CardContent >
                  <ReactMarkdown
                    className="prose-sm max-w-none prose-p:my-2 prose-hr:my-6"
                    remarkPlugins={[remarkGfm]}
                    rehypePlugins={[rehypeHighlight]}
                  >
                    {rs.submission.text}
                  </ReactMarkdown>
                  <div className="flex gap-2 items-center border w-fit px-2 py-1 rounded-xl text-sm">
                    <div className="flex items-center gap-1">
                      <ArrowBigUp className="size-5" /><span>{stats?.upvotes || "?"}</span>
                    </div>
                    <Separator orientation="vertical" className="h-6" />
                    <div className="flex items-center gap-1">
                      <MessageCircle className="size-5" /><span>{stats?.comments || "?"}</span>
                    </div>
                  </div>
                </CardContent>
                <CardFooter>
                  <div className="flex flex-col bg-primary/10 w-full rounded-md p-4">
                    <span className="text-lg font-semibold">Analysis</span>
                    <p className="italic">{rs.reason}</p>
                  </div>
                </CardFooter>
              </Card>
            )
          })
        }
      </div>

    </div >
  )
}

export { RedditInteractions };
