import { SignUp as ClerkSignUp } from "@clerk/clerk-react";
import React from "react";

const SignUp = () => {
  return (
    <ClerkSignUp routing="path" path="/auth/sign-up" signInUrl="/auth/sign-in" />
  )
}

export { SignUp };
