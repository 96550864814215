import { AuthenticateWithRedirectCallback, ClerkProvider } from '@clerk/clerk-react';
import { Toaster } from "@repo/ui/components/ui/sonner";
import "@repo/ui/styles.css";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { StrictMode } from "react";
import { createRoot } from 'react-dom/client';
import {
  RouterProvider,
  createBrowserRouter
} from "react-router-dom";
import { routeObjects } from "./config/routes";
import './index.css';
import { Auth } from "./routes/auth";
import { SignIn } from "./routes/auth.sign-in";
import { SignUp } from "./routes/auth.sign-up";
import { Root } from "./routes/root";

import * as Sentry from "@sentry/react";

const SENTRY_ENVIRONMENT = import.meta.env.VITE_SENTRY_ENVIRONMENT

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
});


const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key")
}

const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    path: "/auth",
    element: <Auth />,
    children: [
      {
        path: "sign-up/sso-callback",
        element: <AuthenticateWithRedirectCallback />,
      },
      {
        path: "sign-in/sso-callback",
        element: <AuthenticateWithRedirectCallback />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "sign-in",
        element: <SignIn />,
      },
    ]
  },
  {
    path: "/",
    element: <Root />,
    children: routeObjects,
  },
]);

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY} afterSignOutUrl="/">
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
        <Toaster
          richColors
          theme="light"
          toastOptions={{}}
        />
      </QueryClientProvider>
    </ClerkProvider>
  </StrictMode>,
)
