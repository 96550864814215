import { SignIn as ClerkSignIn } from "@clerk/clerk-react";
import React from "react";

const SignIn = () => {
  return (
    <ClerkSignIn routing="path" path="/auth/sign-in" signUpUrl="/auth/sign-up" />
  )
}

export { SignIn };
