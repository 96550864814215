import { Badge } from "@repo/ui/components/ui/badge";
import { cva } from "class-variance-authority";
import React from "react";

import { mainCurrenciesSymbols } from "../pricingCard/PricingCard";

interface CommonPlan {
  name: string;
  amountCents: number;
  currency: string;
  interval: string | null;
}

interface ActivePlan extends CommonPlan {
  periodEnd: Date | null;
  status: "active";
}

interface InactivePlan extends CommonPlan {
  periodEnd: Date;
  status: "past_due" | "canceled";
}


export type PlanProps = ActivePlan | InactivePlan;


const planBadgeVariants = cva(
  "",
  {
    variants: {
      status: {
        active: "bg-primary text-primary-foreground",
        past_due: "bg-destructive text-destructive-foreground",
        canceled: "bg-secondary text-secondary-foreground"
      }
    },
    defaultVariants: {
      status: "active"
    }
  }
)

const Plan: React.FC<PlanProps> = ({
  name,
  amountCents,
  currency,
  interval,
  periodEnd,
  status
}) => {
  const currencySymbol = mainCurrenciesSymbols[currency.toUpperCase()] || currency;
  const costCopy = amountCents === 0 ?
    "(free)" : `(${currencySymbol}${amountCents / 100}/${interval})`;
  const periodCopy = status === "active" ?
    "Renews on" : status === "past_due" ? "Due on" : "Canceled on";

  return (
    <div className="flex flex-col gap-1">
      <h3 className="text-2xl font-semibold">Plan</h3>
      <div className="flex gap-8 items-baseline">
        <div className="flex gap-2 items-baseline">
          <span className="text-xl font-medium">{name || "-"}</span>
          <span className="text-sm text-muted-foreground">
            {costCopy}
          </span>
        </div>
        <Badge className={planBadgeVariants({ status })}>
          {status.replace("_", " ")}
        </Badge>
      </div>
      <span className="text-muted-foreground">
        {periodCopy}: {periodEnd ? periodEnd.toDateString() : "forever"}
      </span>
    </div>
  )
}

Plan.displayName = "Plan"

export { Plan };
