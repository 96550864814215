import React, { useState } from "react";
import { PricingCard } from "../pricingCard/PricingCard";

import {
  Card,
  CardContent,
} from "@repo/ui/components/ui/card";
import { Label } from "@repo/ui/components/ui/label";
import { Switch } from "@repo/ui/components/ui/switch";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@repo/ui/components/ui/tabs";
import { components } from '../../services/api/openapi';


interface PricingTableProps {
  pricingDefinitions: components["schemas"]["PricingDefinition"][]
}

const PricingTable: React.FC<PricingTableProps> = ({ pricingDefinitions }) => {
  const [promoApplied, setPromoApplied] = useState(false);
  return (
    <Tabs defaultValue="monthly" className="flex flex-col gap-8 w-full items-center">
      <TabsList className="grid w-60 grid-cols-2">
        <TabsTrigger value="monthly">Monthly</TabsTrigger>
        <TabsTrigger value="yearly">Yearly</TabsTrigger>
      </TabsList>
      <div className="flex flex-col items-center gap-2">
        <Label htmlFor="apply-promo">Apply promo (if available)</Label>
        <Switch
          id="apply-promo"
          checked={promoApplied}
          onCheckedChange={() => setPromoApplied(value => !value)}
        />
      </div>
      <TabsContent value="monthly" className="w-full">
        <Card className="border-0 shadow-none">
          <CardContent className="flex w-full gap-4">
            {pricingDefinitions.map((def) => {
              return (
                <PricingCard
                  key={`monthly-${def.plan.name}`}
                  variant="monthly"
                  promoActive={promoApplied}
                  name={def.plan.name}
                  description={def.plan.description}
                  currency={def.billing.currency}
                  promoName={def.promo.name}
                  priceAmountMonthly={def.billing.monthly.price_amount}
                  priceAmountYearly={def.billing.yearly.price_amount}
                  priceAmountMonthlyPromo={def.promo.monthly?.price_amount || null}
                  priceAmountYearlyPromo={def.promo.yearly?.price_amount || null}
                  priceLabelMonthly="/mo"
                  priceLabelYearly="/yr"
                  ctaText="Select plan"
                  ctaLink={def.billing.monthly.payment_link}
                  features={def.plan.features}
                  limits={def.plan.limits}
                />
              )
            })}
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value="yearly" className="w-full">
        <Card className="border-0">
          <CardContent className="flex w-full gap-4">
            {pricingDefinitions.map((def) => {
              return (
                <PricingCard
                  key={`yearly-${def.plan.name}`}
                  variant="yearly"
                  promoActive={promoApplied}
                  name={def.plan.name}
                  description={def.plan.description}
                  currency={def.billing.currency}
                  promoName={def.promo.name}
                  priceAmountMonthly={def.billing.monthly.price_amount}
                  priceAmountYearly={def.billing.yearly.price_amount}
                  priceAmountMonthlyPromo={def.promo.monthly?.price_amount || null}
                  priceAmountYearlyPromo={def.promo.yearly?.price_amount || null}
                  priceLabelMonthly="/mo"
                  priceLabelYearly="/yr"
                  ctaText="Select plan"
                  ctaLink={def.billing.yearly.payment_link}
                  features={def.plan.features}
                  limits={def.plan.limits}
                />
              )
            })}
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  )
}

PricingTable.displayName = "PricingTable";

export { PricingTable };
