import { LoaderCircle } from 'lucide-react';
import React from "react";

const Spinner = () => {
  return (
    <div className="w-full flex flex-col items-center justify-center">
      <LoaderCircle className="animate-spin" />
    </div>
  )
}

Spinner.displayName = "Spinner";

export { Spinner };
