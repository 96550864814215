import { UserProfile } from "@clerk/clerk-react";
import React from "react";

const Account = () => {
  return (
    <UserProfile
      appearance={{
        elements: {
          rootBox: "w-full h-full",
          cardBox: "shadow-none border w-full h-full"
        }
      }}
    />
  )
}

export { Account };
