import { useAuth, useUser } from '@clerk/clerk-react';
import { toast } from "sonner";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import { useNavigate } from "react-router-dom";

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef } from "react";
import { fetcher, serializeError } from '../services/api';
import { components } from '../services/api/openapi';


const generateRandomHex = (): string =>
  Math.floor(Math.random() * 0xffffff).toString(16).padStart(6, '0')


const ProjectsCreate = () => {
  const queryClient = useQueryClient();
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();
  const isCalled = useRef(false);
  const navigate = useNavigate();

  const projectsCreation = useMutation({
    mutationFn: async (projectPayload: components["schemas"]["CreateProjectRequest"]) => {
      const { data, error, response } = await fetcher.POST("/projects", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        body: projectPayload
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['get', 'projects'] });
      toast.success("New project created!",
        { description: data.data.name })
      return navigate(`/projects/edit/${data.data.id}`);
    },
    onError: (error) => {
      toast.error("Cannot create project",
        { description: `${error}` })
    },
  })

  useEffect(() => {
    if (isSignedIn) {
      if (!isCalled.current) {
        isCalled.current = true
        projectsCreation.mutate({
          name: `New Project ${generateRandomHex()}`,
          website: "",
          description: "",
          keywords: []
        })
      }
    }
  }, [isSignedIn])

  return null
}

export { ProjectsCreate };
