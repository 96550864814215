import React from "react";
import { Outlet } from "react-router-dom";

const Auth = () => {
  return (
    <div className="flex w-full min-h-screen items-center justify-center">
      <Outlet />
    </div>
  )
}

export { Auth };
