import { useAuth, useUser } from '@clerk/clerk-react';
import { Infinity } from 'lucide-react';

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import { Progress } from "@repo/ui/components/ui/progress";
import { useQuery } from '@tanstack/react-query';
import invariant from 'tiny-invariant';
import { fetcher, serializeError } from '../../services/api';
import { Spinner } from '../spinner/Spinner';

import { Button } from "@repo/ui/components/ui/button";
import React, { useEffect, useReducer } from "react";
import { Link } from "react-router-dom";


interface FeatureUsage {
  featureName: string;
  usage: number;
  usagePerc: number;
  limit: number | null;
}

interface InitState {
  data: null;
  error: null;
  status: "pending"
};

interface OkState {
  data: FeatureUsage[];
  error: null;
  status: "success"
};

interface KoState {
  data: null;
  error: Error;
  status: "error"
};

type State = InitState | OkState | KoState;

type Action =
  | { type: 'set-data'; payload: FeatureUsage[] }
  | { type: 'set-error'; payload: Error }
  | { type: 'reset' };

const initialState: State = {
  data: null,
  error: null,
  status: "pending",
};

const reducer = (_: State, action: Action): State => {
  switch (action.type) {
    case 'set-data':
      return { data: action.payload, error: null, status: "success" };
    case 'set-error':
      return { data: null, error: action.payload, status: "error" };
    case 'reset':
      return { ...initialState };
  }
};

const useUsageData = (): State => {
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    data: featuresQData,
    error: featuresQError,
    status: featuresQStatus
  } = useQuery({
    queryKey: ["list", "features"],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/features", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          query: {
            expand: ["limits"]
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  const {
    data: featuresUsagesQData,
    error: featuresUsagesQError,
    status: featuresUsagesQStatus
  } = useQuery({
    queryKey: ["list", "features-usages"],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/features-usages", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
        params: {
          query: {
            expand: ["subscription"]
          }
        }
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  useEffect(() => {
    if (featuresQStatus === "success" && featuresUsagesQStatus === "success") {
      if (featuresUsagesQData.data.length === 0) {
        dispatch({ type: 'set-data', payload: [] })
      } else {
        const subscription = featuresUsagesQData.data[0].subscription;
        if (!subscription) {
          dispatch({ type: 'set-data', payload: [] })
        } else {

          const plan_id = subscription.plan_id;
          invariant(plan_id, "Unexpected missing plan id")

          const featuresUsage = featuresUsagesQData.data.reduce((acc, item) => {
            acc[item.feature_id] = item.usage;
            return acc;
          }, {});

          const featuresUsageFullData = featuresQData.data.map(f => {
            const usage = featuresUsage[f.id];
            invariant(f.limits, "Unexpected missing limits")
            const limitRecord = f.limits.filter(f => f.plan_id === plan_id);
            invariant(limitRecord.length > 0, "Unexpected missing limit record")
            const limit = limitRecord[0].limit || null;
            const usagePerc = Math.min(Math.max(limit ? usage / limit * 100 : 0, 0), 100);
            return {
              featureName: f.name,
              usage,
              usagePerc,
              limit,
            }
          })

          dispatch({ type: 'set-data', payload: featuresUsageFullData })
        }
      }
    } else if (featuresQStatus === "error" || featuresUsagesQStatus === "error") {
      dispatch({ type: 'set-error', payload: (featuresQError || featuresUsagesQError) as Error })
    } else {
      dispatch({ type: 'reset' });
    }
  }, [
    featuresQStatus, featuresUsagesQStatus,
    featuresQData, featuresUsagesQData,
    featuresQError, featuresUsagesQError
  ])

  return { ...state }
}


const UsageCardBody = () => {
  const { data: featuresUsages, error, status } = useUsageData();

  if (status === "pending") {
    return (
      <CardContent className="p-4 pt-0 flex flex-col gap-2">
        <Spinner />
      </CardContent>
    )
  }

  if (status === "error") {
    return (
      <CardContent className="p-4 pt-0 flex flex-col gap-2">
        <span>Error: {error.message}</span>
      </CardContent>
    )
  }

  if (featuresUsages.length === 0) {
    return null;
  }

  return (
    <>
      <CardContent className="p-4 pt-0 flex flex-col gap-2">
        {
          featuresUsages.map((fu, i) => (
            <div key={`${fu.featureName}-${i}`} className="flex flex-col gap-1">
              <div className="flex justify-between text-sm">
                <span>{fu.featureName}</span>
                <span>{fu.usage}/{fu.limit ?
                  fu.limit : <Infinity className="inline" size={16} />}</span>
              </div>
              <Progress
                variant={fu.usagePerc === 100 ? "accent" : "default"}
                value={fu.usagePerc} className="w-full h-1"
              />
            </div>))
        }
      </CardContent>
      <CardFooter className="p-4 pt-0">
        <Button className="w-full" variant="accent" size="sm" aria-label="start" asChild>
          <Link to="/profile/subscription">
            Upgrade
          </Link>
        </Button>
      </CardFooter>
    </>
  )
}


UsageCardBody.displayName = "UsageCardBody";


const UsageCard = () => {
  return (
    <div className="flex flex-col py-4">
      <Card className="w-full">
        <CardHeader className="p-4">
          <CardTitle className="text-xl">Usage</CardTitle>
        </CardHeader>
        <UsageCardBody />
      </Card>
    </div>
  )
}


UsageCard.displayName = "UsageCard";


export { UsageCard };
