import { Badge } from "@repo/ui/components/ui/badge";
import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/tooltip";
import { Check, Info, X } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";
import { components } from '../../services/api/openapi';

const mainCurrenciesSymbols: Record<string, string> = {
  "USD": "$",    // United States Dollar
  "EUR": "€",    // Euro
  "GBP": "£",    // British Pound Sterling
  "AUD": "A$",   // Australian Dollar
  "CAD": "C$",   // Canadian Dollar
  "CHF": "CHF",  // Swiss Franc
  "CNY": "¥",    // Chinese Yuan Renminbi
  "SEK": "kr",   // Swedish Krona
  "NZD": "NZ$",  // New Zealand Dollar
  "JPY": "¥",    // Japanese Yen
  "MXN": "MXN$", // Mexican Peso
  "SGD": "S$",   // Singapore Dollar
  "HKD": "HK$",  // Hong Kong Dollar
  "NOK": "kr",   // Norwegian Krone
  "KRW": "₩",    // South Korean Won
  "INR": "₹",    // Indian Rupee
  "DKK": "kr",   // Danish Krone
  "RUB": "₽",    // Russian Ruble
  "BRL": "R$",   // Brazilian Real
  "ZAR": "R"     // South African Rand
};

interface PricingCardProps {
  variant: string;
  promoActive: boolean;
  name: string;
  description: string;
  currency: string;
  promoName: string;
  priceAmountMonthly: number,
  priceAmountYearly: number,
  priceAmountMonthlyPromo: number | null,
  priceAmountYearlyPromo: number | null,
  priceLabelMonthly: string;
  priceLabelYearly: string;
  ctaText: string;
  ctaLink: string;
  features: components["schemas"]["PlanFeature"][];
  limits: components["schemas"]["PlanLimit"][];
}


const PricingCard: React.FC<PricingCardProps> = ({
  variant,
  promoActive,
  name,
  description,
  currency,
  promoName,
  priceAmountMonthly,
  priceAmountYearly,
  priceAmountMonthlyPromo,
  priceAmountYearlyPromo,
  priceLabelMonthly,
  priceLabelYearly,
  ctaText,
  ctaLink,
  features,
  limits,
}) => {
  const isPromoAvailable = promoActive && promoName && (
    (variant === "monthly" && priceAmountMonthlyPromo) ||
    (variant === "yearly" && priceAmountYearlyPromo)
  )

  const currencySymbol = mainCurrenciesSymbols[currency.toUpperCase()];
  const priceAmount = variant === "monthly" ?
    priceAmountMonthly :
    Math.round(priceAmountYearly / 12);
  const priceAmountPromo = variant === "monthly" ?
    priceAmountMonthlyPromo :
    priceAmountYearlyPromo ? Math.round(priceAmountYearlyPromo / 12) : null;

  const yearlyDiscount = isPromoAvailable ? (
    priceAmountYearlyPromo ?
      Math.round(100 - (priceAmountYearlyPromo * 100 / (priceAmountMonthly * 12))) :
      null
  ) : Math.round(100 - (priceAmountYearly * 100 / (priceAmountMonthly * 12)));

  return (
    <Card className="w-full">
      <CardHeader className="text-center flex flex-col gap-4 items-center">
        {isPromoAvailable && (
          <Badge className="uppercase font-semibold text-center justify-center">
            {promoName} promo!
          </Badge>
        )}
        <div className="flex flex-col gap-2">
          <CardTitle className="text-3xl">{name}</CardTitle>
          <CardDescription className="text-lg">{description}</CardDescription>
        </div>
      </CardHeader>
      <CardContent className="flex flex-col gap-8">
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-2 items-center">
            <div className="flex gap-2">
              <div className="flex gap-1">
                <span className="text-3xl font-semibold">
                  {currencySymbol}
                </span>
                <span className="text-7xl font-semibold">
                  {isPromoAvailable ? (
                    <div className="flex gap-1">
                      <span className="line-through decoration-wavy decoration-primary">{priceAmount}</span>
                      {priceAmountPromo && <span className="text-primary text-8xl">{priceAmountPromo}</span>}
                    </div>

                  ) : (
                    <span>{priceAmount}</span>
                  )}
                </span>
              </div>
              <span className="text-muted-foreground text-2xl self-end pb-1">{priceLabelMonthly}</span>
            </div>

            {variant === "yearly" && (
              <div className="flex flex-col items-center gap-1">
                <div className="flex gap-1 text-lg">
                  <div className="flex gap-2 text-lg">
                    <span className="text-muted-foreground line-through">{currencySymbol}{priceAmountMonthly * 12}</span>
                    {
                      isPromoAvailable ? (
                        <span className="text-primary font-semibold">{currencySymbol}{priceAmountYearlyPromo}</span>
                      ) : (
                        <span>{currencySymbol}{priceAmountYearly}</span>
                      )
                    }

                  </div>
                  <span className="text-muted-background">{priceLabelYearly}</span>
                </div>
                {isPromoAvailable ? (
                  <span className="italic text-sm text-primary font-semibold">(-{yearlyDiscount}%)</span>
                ) : (
                  <span className="italic text-sm text-muted-background">(-{yearlyDiscount}%)</span>
                )}
              </div>
            )}

          </div>
          <Button asChild aria-label={ctaText}>
            <Link to={ctaLink}>
              {ctaText}
            </Link>
          </Button>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <span className="text-lg font-medium">Features:</span>
            <div className="flex flex-col gap-1">
              {
                features.map(f => (
                  <div key={f.description.toLowerCase().replace(" ", "-")} className="flex gap-2">
                    {
                      f.available ? (
                        <Check className="text-primary" />
                      ) : (
                        <X className="text-destructive" />
                      )
                    }
                    <span className={f.available ? "" : "line-through"}>{f.description}</span>
                  </div>
                ))
              }
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <span className="text-lg font-medium">Limits:</span>
            <div className="flex flex-col gap-1">
              {
                limits.map(l => (
                  <div key={l.feature.toLowerCase().replace(" ", "-")} className="flex gap-2 justify-between">
                    <span className="flex gap-2">
                      <span>{l.feature}</span>
                      <TooltipProvider delayDuration={100}>
                        <Tooltip>
                          <TooltipTrigger>
                            <Info className="w-4 h-4 text-muted-foreground" />
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{l.tooltip}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </span>
                    {l.quota > 0 ? (
                      <span><span className="font-semibold">{l.quota}</span> {l.unit}</span>
                    ) : l.unlimited ? (
                      <span className="font-semibold">unlimited</span>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                ))
              }
            </div>
          </div>
        </div>

      </CardContent>
    </Card>
  )
}

PricingCard.displayName = "PricingCard";

export { PricingCard, mainCurrenciesSymbols };
