import { useAuth, useUser } from '@clerk/clerk-react';
import { Button } from "@repo/ui/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/card";
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "../organisms/";
import { fetcher, serializeError } from '../services/api';

const CTA_URL = "/projects/create";


const RootIndex = () => {
  const { getToken } = useAuth();
  const { isSignedIn } = useUser();
  const [showCTA, setShowCTA] = useState<boolean | null>(null);
  const { data, error, status } = useQuery({
    queryKey: ["get", "projects"],
    queryFn: async () => {
      const { data, error, response } = await fetcher.GET("/projects", {
        headers: {
          Authorization: `Bearer ${await getToken()}`,
          'Content-Type': 'application/json',
        },
      })

      if (error) {
        throw serializeError(error, response.status)
      }

      return data;
    },
    enabled: isSignedIn
  })

  useEffect(() => {
    if (status === "success") {
      const projects = data.data;
      setShowCTA(projects.length === 0);
    }
  }, [status, data])

  if (status === "pending" || showCTA === null) {
    return (
      <Spinner />
    )
  }

  if (status === "error") {
    return <span>Error: {error.message}</span>
  }


  return (
    showCTA ?
      <RootIndexCTA /> :
      <RootIndexDashboard />
  )
}

const RootIndexDashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    return navigate("/projects");
  }, []);

  return null;
}

const RootIndexCTA = () => {
  return (
    <div className="flex flex-col w-full h-full items-center justify-center">
      <Card className="min-w-80 w-80">
        <CardHeader>
          <CardTitle className="text-center">Get started</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Create a project now to get started!</p>
        </CardContent>
        <CardFooter>
          <Button className="w-full" aria-label="start" asChild>
            <Link to={CTA_URL}>
              Create project
            </Link>
          </Button>
        </CardFooter>
      </Card>
    </div>
  )
}

export { RootIndex };
