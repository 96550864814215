import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import { components } from './services/api/openapi';

interface PrefsState {
  currentProject: components["schemas"]["Project"] | null;
  setCurrentProject: (project: components["schemas"]["Project"]) => void;
  resetCurrentProject: () => void
}

const usePrefsStore = create<PrefsState>()(
  devtools(
    persist(
      (set) => ({
        currentProject: null,
        setCurrentProject: (project) => set(() => ({ currentProject: project })),
        resetCurrentProject: () => set(() => ({ currentProject: null })),
      }),
      {
        name: 'perfs-storage',
      },
    ),
  ),
)

export { usePrefsStore };
